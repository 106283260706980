html,
body {
  background: #fefefe;
  margin: 0;
  padding: 0;
  height: 100%;
  font-display: swap;
}

body {
  min-height: 100%;
}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
